declare global {
    interface Document {
        documentMode?: any;
    }
    interface Window {
        MSInputMethodContext?: any;
        dataLayer?: any;
    }
}

export const isIE: boolean = window.MSInputMethodContext && window.document.documentMode ? true : false;

export const isStorybook = (): boolean => {
    var url = window.location.href.toLowerCase();
    return url.indexOf('://localhost:6006/') !== -1 || (url.indexOf(':6006/') !== -1 && url.indexOf('force-storybook=true') !== -1);
};

export const isPrototype = (): boolean => {
    var url = window.location.href.toLowerCase();
    return url.indexOf('://localhost:3000/') !== -1 || (url.indexOf(':3000/') !== -1 && url.indexOf('force-prototype=true') !== -1);
};

export const guid = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const inputId = (): string => {
    return `input_${guid()}`;
};

export const columnClass = (colCount: number | undefined): string => {
    if (!colCount) {
        return 'col-12';
    }
    if (colCount === 5 || colCount > 6) {
        return 'col';
    }
    return `col-${12 / colCount}`;
};

export const removeSpaces = (str: string) => {
    return str.replace(/\s/g, '');
};

export const replaceSpaces = (str: string, replaceValue: string) => {
    return str.replace(/\s/g, `${replaceValue}`);
};

export const getUrl = (queryString: string | undefined) => {
    var url = window.location.protocol + '//' + window.location.host + window.location.pathname;
    return queryString ? url + '?' + queryString : url;
};

export const getQueryParam = (key: string) => {
    return new URLSearchParams(window.location.search).get(key) || '';
};

export const setQueryParam = (key: string, value: string | Array<string>, replace: boolean = true) => {
    let searchParams = new URLSearchParams(window.location.search);
    if (!value.length) {
        searchParams.delete(key);
    } else {
        searchParams.set(key, Array.isArray(value) ? `${value.join(',')}` : value);
    }
    let newurl = getUrl(searchParams.toString());
    if (replace) {
        window.history.replaceState({ path: newurl }, '', newurl);
    } else {
        window.history.pushState({ path: newurl }, '', newurl);
    }
};

export const trackPageView = (path: string, title: string) => {
    window.dataLayer.push({
        event: 'page_view',
        virtual_path: path,
        virtual_title: title,
    });
};
